import request from "@/utils/request";
import fileApi from '@/const/apiUrl/file';
import method from "@/const/request/requestMethod";
import contentType from "@/const/request/requestHeader";
//上传图片
export function uploadImage(params) {
    return request({
        url: fileApi.uploadImage,
        method: method.POST,
        data: params,
        // headers: {
        //     'Content-Type': contentType.formData
        //   }
    })
}

//身份证OCR
export function ocrIdcard(params) {
    return request({
        url: fileApi.ocrIdcard,
        method: method.POST,
        data: params,
           headers: {
            'Content-Type': contentType.formData
          }
    })
}
//营业执照
export function license(params) {
    return request({
        url: fileApi.license,
        method: method.POST,
        data: params
    })
}
//上传图片（私有）
export function privacy(params) {
    return request({
        url: fileApi.privacy,
        method: method.POST,
        data: params
    })
}
//上传公告
export function editNoticeTopMarquee(params) {
    return request({
        url: fileApi.editNoticeTopMarquee,
        method: method.POST,
        data: params,
        headers: {
            'Content-Type': contentType.formURL
          }
    })
}
//获取已上传公告
export function getNoticeTopMarquee(params) {
    return request({
        url: fileApi.getNoticeTopMarquee,
        method: method.GET,
        data: params,
    })
}
