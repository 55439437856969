const storeApi = {
    //店铺列表
    storeList: '/store/list',
    //根据ID启用[停用]店铺
    changeEnabled: '/store/changeEnabled',
    //新建店铺
    storeAdd: '/store/add',
    //获取店铺详情
    storeDetail: '/store/getById',
    //编辑店铺
    storeEdit: '/store/edit',
     //向微信提交
     storeToWechat:'/store/submitWechatMerchant',
    //店铺状态
    storeOptionStatus: '/store/options/status',
    //根据ID设置店铺权重
    storeChangeWeight: '/store/changeWeight',
    //向微信查询申请状态
    storeQueryMerchant: '/store/queryMerchant',
    //新发地掌鲜文档中心APIs
    uploadGetStsUrl: '/upload/getStsUrl',
    //开户行选项
    BankAccounts:'/store/options/adaPayBankInfo',
    //上传用户协议
    agreement:'/upload/agreement',
    //下载店铺维护情况
    maintainStore:'/store/download/maintainStore',
    //审核状态列表
     storeauditList:'/store/auditList',
 
}


export default storeApi
