import request from "@/utils/request";
import userApi from "@/const/apiUrl/permission";
import method from "@/const/request/requestMethod";

//权限资源树表
export function permissions(params) {
  return request({
    url: userApi.Permissions,
    method: method.GET,
    data: params
  });
}

//获取登录菜单权限
export function loginOut() {
  return request({
    url: "/user/loginOut",
    method: method.POST,
  });
}


//获取登录菜单权限
export function userSysPerms(params) {
  return request({
    url: userApi.userSysPerms,
    method: method.GET,
    data: params
  });
}

//获取节假日期
export function getFestivalTime(params) {
  return request({
    url: userApi.festivalTime,
    method: method.GET,
    data: params
  });
}

//获取节假日期
export function setFestivalTime(params) {
  return request({
    url: userApi.editFestivalTime,
    method: method.POST,
    data: params
  });
}
//新建权限资源
export function permissionAdd(params) {
  return request({
    url: userApi.permissionAdd,
    method: method.POST,
    data: params
  });
}
//新建权限资源
export function setpermissionUpdate(params) {
  return request({
    url: userApi.setpermissionUpdate,
    method: method.POST,
    data: params
  });
}
//新建树状菜单列表
export function PermissionsAll(params) {
  return request({
    url: userApi.PermissionsAll,
    method: method.get,
    data: params
  });
}
//删除权限资源
export function permissionsDel(params) {
  return request({
    url: userApi.permissionsDel,
    method: method.get,
    data: params
  });
}

