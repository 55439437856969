const regionApi={
// 获取全国省市区列表
getRegion:'/wxCapital/areas/allCities',
// 微信支持的银行列表
wxCapitalbanks:'/wxCapital/banks/list',
//微信支持的省列表
wxCapitalProvinces:'/wxCapital/areas/provinces',
//微信支持的市列表
wxCapitalCity:'/wxCapital/areas/cities',
//微信支持的银行支行
wxCapitalbranches:'/wxCapital/banks/branches'
}




 export default regionApi