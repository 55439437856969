import request from "@/utils/request";
import storeApi from '@/const/apiUrl/store'
import method from "@/const/request/requestMethod";
import contentType from "@/const/request/requestHeader";
import region from "@/const/apiUrl/region";
//店铺列表
export function storeList(searchData) {
    return request({
        url: storeApi.storeList,
        method: method.GET,
        data: searchData
    })
}

//店铺开启 停用
export function changeEnabled(params) {
    return request({
        url: storeApi.changeEnabled,
        method: method.GET,
        data: params
    })
}
//店铺 修改状态
export function changeStatus(params) {
    return request({
        url: '/store/changeStatus',
        method: method.GET,
        data: params
    })
}
//向微信提交
export function toWechat(params) {
    return request({
        url: '/store/submitWechatMerchant',
        method: method.GET,
        data: params
    })
}
//查询提交状态
export function getWechatStaus(params) {
    return request({
        url: '/store/queryMerchant',
        method: method.GET,
        data: params
    })
}
//新建店铺
export function storeAdd(params) {
    return request({
        url: storeApi.storeAdd,
        method: method.POST,
        data: params
    })
}

// 获取店铺详情
export function storeDetail(params) {
    return request({
        url: storeApi.storeDetail,
        method: method.GET,
        data: params
    })
}

//店铺编辑
export function storeEdit(params) {
    return request({
        url: storeApi.storeEdit,
        method: method.POST,
        data: params
    })
}

//店铺状态
export function storeOptionStatus(params) {
    return request({
        url: storeApi.storeOptionStatus,
        method: method.GET,
        data: params
    })
}

//根据ID设置店铺权重
export function storeChangeWeight(params) {
    return request({
        url: storeApi.storeChangeWeight,
        method: method.GET,
        data: params
    })
}
// 向微信查询申请状态
export function storeQueryMerchant(params) {
    return request({
        url: storeApi.storeQueryMerchant,
        method: method.GET,
        data: params
    })
}

export function BankAccounts(params) {
    return request({
        url: storeApi.BankAccounts,
        method: method.GET,
        data: params,
    })
}

// 新发地掌鲜文档中心APIs
export function uploadGetStsUrl(params) {
    return request({
        url: storeApi.uploadGetStsUrl,
        method: method.POST,
        data: params,
        headers: {
            'Content-Type': contentType.formURL
        }
    })
}

// 上传用户协议
export function agreement(params) {
    return request({
        url: storeApi.agreement,
        method: method.POST,
        data: params,
    })
}

// 下载店铺维护情况
export function maintainStore(params) {
    return request({
        url: storeApi.maintainStore,
        method: method.GET,
        data: params,
    })
}
// 审核状态列表
export function storeStatuslist(params) {
    return request({
        url: storeApi.storeStatuslist,
        method: method.GET,
        data: params,
    })
}
// 全国省市区列表
export function getRegionApi(params) {
    return request({
        url: region.getRegion,
        method: method.GET,
        data: params,
    })
}
//微信支持的银行列表
export function getwxCapitalbanks(params) {

    return request({
        url: region.wxCapitalbanks,
        method: method.POST,
        data: params,
        headers: {
            'Content-Type': contentType.formURL
        }
    })
}
//微信的省列表
export function getwxCapitalProvinces(params) {

    return request({
        url: region.wxCapitalProvinces,
        method: method.GET,
        data: params,

    })
}

//微信的市列表
export function getwxCapitalCity(params) {

    return request({
        url: region.wxCapitalCity,
        method: method.GET,
        data: params,

    })
}
//微信的银行支行列表
export function getwxCapitalbranches(params) {

    return request({
        url: region.wxCapitalbranches,
        method: method.GET,
        data: params,

    })
}
