/**
 * 请求接口地址常量
 */

const ofApi = {
  //权限资源树
  Permissions: "/permission/sysPermsAll",
  //登录当前资源数
  userSysPerms: "/user/sysPerms",
  // 获取节假日
  festivalTime: "/setting/holiday/get",
  // 编辑节假日
  editFestivalTime: "/setting/holiday/edit",
  PermissionsAll: "/permission/sysPermsAllAll",
  permissionAdd:'/permission/add',
  setpermissionUpdate:'/permission/update',
  permissionsDel:'/permission/delete'
};

export default ofApi;
