import { MessageBox } from "element-ui";

export const box = () => {
    return MessageBox.confirm(`
    <img style="width:40px;height:40px;margin-bottom:10px;margin-top:-20px" src="/prompt.png"></img>
    <div style="font-wight:bold;font-size:18px;color:black;">删除数据</div>
    <div style="color:#777777;margin-top:10px;margin-bottom:8px;">数据删除将无法找回,请谨慎处理</div>
    `,  {
            confirmButtonText: "删除数据",
            cancelButtonText: "取消",
            // type: "warning",
            center: true,
            dangerouslyUseHTMLString: true,
            showClose:false,
            cancelButtonClass:'cancelBox',
            confirmButtonClass:'sureBox'
        })
        .then(() => true)
        .catch(() => false);
};

export const confirm = (msg,msg1) => {
    if(!msg1){
      msg1 = "";
    }
    return MessageBox.confirm(`
    <img style="width:40px;height:40px;margin-bottom:10px;margin-top:-20px" src="/prompt.png"></img>
    <div style="font-wight:bold;font-size:18px;color:black;">`+msg+`</div>
    <div style="color:#777777;margin-top:10px;margin-bottom:8px;">`+msg1+`</div>
    `,  {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            // type: "warning",
            center: true,
            dangerouslyUseHTMLString: true,
            showClose:false,
            cancelButtonClass:'cancelBox',
            confirmButtonClass:'sureBox'
        })
        .then(() => true)
        .catch(() => false);
};
