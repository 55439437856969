import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/login.vue' //登录
import Index from '../views/index.vue' //left-top
import Home from '../views/home/list.vue' //首页


Vue.use(VueRouter)

const routes = [{
	path: '',
	redirect: '/login',
}, {
	path: '/login',
	name: 'Login',
	component: Login
},
{
	path: '/home',
	name: 'Index',
	component: Index,
	children: [

		{
			path: '/home',
			name: 'Home',
			component: Home
		},
		{
			path: '/department/list',
			name: 'departmentList',
			component: () => import('@/views/system/department/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //部门
		{
			path: '/role/list',
			name: 'roleList',
			component: () => import('@/views/system/role/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //角色列表
		{
			path: '/role/add',
			name: 'roleAdd',
			component: () => import('@/views/system/role/add.vue')
		}, //新建角色
		{
			path: '/upload/image',
			name: 'image',
			component: () => import('@/views/upload/image.vue')
		}, //图片上传
		{
			path: '/upload/file',
			name: 'imgFile',
			component: () => import('../views/upload/file.vue')
		}, //图片上传
		{
			path: '/upload/agreement',
			name: 'agreement',
			component: () => import('@/views/upload/agreement.vue')
		}, //平台协议上传
		{
			path: '/upload/getStsUrl',
			name: 'getStsUrl',
			component: () => import('@/views/upload/getStsUrl.vue')
		}, //临时访问链接
		{
			path: '/upload/privacy/file',
			name: 'file',
			component: () => import('..//views/upload/privacy/file.vue')
		}, //文件上传
		{
			path: '/ocr/license',
			name: 'license',
			component: () => import('../views/ocr/license.vue')
		}, //营业执照OCR
		{
			path: '/ocr/idcard',
			name: 'idcard',
			component: () => import('../views/ocr/idcard.vue')
		}, //身份中OCR
		{
			path: '/permission/delete',
			name: 'delete',
			component: () => import('../views/permission/delete.vue')
		}, //删除权限资源
		{
			path: '/permission/add',
			name: 'permissionAdd',
			component: () => import('../views/permission/add.vue')
		}, //添加权限资源
		{
			path: '/worksheet/list',
			name: 'worksheetList',
			component: () => import('../views/worksheet/list.vue')
		}, //导出Excel列表
		{
			path: '/worksheet/delete',
			name: 'worksheetDelete',
			component: () => import('../views/worksheet/delete.vue')
		}, //删除导出Excel列表
		{
			path: '/role/edit',
			name: 'roleEdit',
			component: () => import('@/views/system/role/edit.vue')
		}, //角色编辑
		{
			path: '/user/list',
			name: 'userList',
			component: () => import('@/views/system/user/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, // 成员
		{
			path: '/user/add',
			name: 'userAdd',
			component: () => import('@/views/system/user/add.vue')
		}, //成员添加
		{
			path: '/user/getCaptcha',
			name: 'getCaptcha',
			component: () => import('@/views/system/user/getCaptcha.vue')
		}, //图片验证
		{
			path: '/user/edit',
			name: 'userEdit',
			component: () => import('@/views/system/user/edit.vue')
		}, //成员编辑
		{
			path: '/user/sysPerms',
			name: 'usersysPerms',
			component: () => import('@/views/system/user/sysPerms.vue')
		}, //员工后台权限
		{
			path: '/member/list',
			name: 'memberList',
			component: () => import('@/views/member/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //会员管理
		{
			path: '/member/Detail',
			name: 'memberDetail',
			component: () => import('@/views/member/detail.vue')
		}, //会员详情
		{
			path: '/order/list',
			name: 'orderList',
			component: () => import('@/views/order/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //订单管理, //会员详情
		{
			path: '/order/subList',
			name: 'subList',
			component: () => import('@/views/order/subList.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //订单管理
		{
			path: '/order/options/payment',
			name: 'payment',
			component: () => import('../views/upload/image'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //支付方式类型
		{
			path: '/order/options/source',
			name: 'source',
			component: () => import('../views/order/options/source.vue'),

		}, //订单来源类型选项
		{
			path: '/order/detail',
			name: 'orderDetail',
			component: () => import('@/views/order/detail.vue')
		}, //订单详情
		{
			path: '/setting/express/list',
			name: 'expressList',
			component: () => import('@/views/setting/express/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //快递列表
		{
			path: '/setting/express/add',
			name: 'expressAdd',
			component: () => import('@/views/setting/express/add.vue')
		}, //快递新增
		{
			path: '/setting/express/edit',
			name: 'expressEdit',
			component: () => import('@/views/setting/express/edit.vue')
		}, //快递编辑
		{
			path: '/dict',
			name: 'dictList',
			component: () => import('@/views/setting/dict/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //字典列表
		{
			path: '/setting/shipper/list',
			name: 'shipperList',
			component: () => import('@/views/setting/shipper/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //地址列表
		{
			path: '/setting/shipper/add',
			name: 'shipperAdd',
			component: () => import('@/views/setting/shipper/add.vue')
		}, //地址添加
		{
			path: '/setting/shipper/edit',
			name: 'shipperEdit',
			component: () => import('@/views/setting/shipper/edit.vue')
		}, //地址编辑
		{
			path: '/setting/member/level/list',
			name: 'levelList',
			component: () => import('@/views/member/level/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //会员等级列表
		{
			path: '/setting/member/level/add',
			name: 'levelAdd',
			component: () => import('@/views/member/level/add.vue')
		}, //会员等级新建
		{
			path: '/setting/member/level/edit',
			name: 'levelEdit',
			component: () => import('@/views/member/level/edit.vue')
		}, //会员等级编辑
		{
			path: '/setting/order/reason/list',
			name: 'reasonList',
			component: () => import('@/views/setting/reason/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //退换货原因列表
		{
			path: '/setting/order/reason/add',
			name: 'reasonAdd',
			component: () => import('@/views/setting/reason/add.vue')
		}, //新建退换货原因
		{
			path: '/permission/sysPermsAllAll',
			name: 'authorityManagement',
			component: () => import('../views/setting/authorityManagement.vue')
		}, //权限管理
		{
			path: '/setting/order/reason/edit',
			name: 'reasonEdit',
			component: () => import('@/views/setting/reason/edit.vue')
		}, //编辑退换货原因
		{
			path: '/order/return/list',
			name: 'return',
			component: () => import('@/views/order/return/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //退款列表
		{
			path: '/order/refund/list',
			name: 'refund',
			component: () => import('@/views/order/refund/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //换货列表
		{
			path: '/order/refund/detail',
			name: 'refundDetail',
			component: () => import('@/views/order/refund/detail.vue')
		}, //售后订单详情
		{
			path: '/order/orderStatus',
			name: 'orderStatus',
			component: () => import('@/views/order/orderStatus.vue')
		}, //订单状态选项
		{
			path: '/order/return/detail',
			name: 'returnDetail',
			component: () => import('@/views/order/return/detail.vue')
		}, //售后订单详情
		{
			path: '/store/list',
			name: 'storeList',
			component: () => import('@/views/store/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //店铺定期抽检
		{
			path: '/store/download/maintainStore',
			name: 'maintainStore',
			component: () => import('../views/store/download/maintainStore.vue'),

		}, //店铺列表
		{
			path: '/store/options/adaPayBankInfo',
			name: 'adaPayBankInfo',
			component: () => import('../views/store/options/adaPayBankInfo.vue'),

		}, //汇付开户银行选项
		{
			path: '/store/add',
			name: 'storeAdd',
			component: () => import('@/views/store/add.vue')
		}, //新建店铺
		{
			path: '/store/detail',
			name: 'storeDetail',
			component: () => import('@/views/store/detail.vue')
		}, //店铺详情
		{
			path: '/bd/maintain/BD_MAIN',
			name: 'storeBDMAIN',
			component: () => import('@/views/store/BD_MAIN.vue')
		}, //BD工作台提示页面
		{
			path: '/BD_MAIN',
			name: 'storeBD_MAIN',
			component: () => import('@/views/store/BD_MAIN.vue')
		}, //BD工作台提示页面
		{
			path: '/store/edit',
			name: 'storeEdit',
			component: () => import('@/views/store/edit.vue')
		}, //店铺详情
		{
			path: '/store/auditList',
			name: 'storeauditList',
			component: () => import('@/views/store/StoreAudit.vue')
		}, //店铺审核
		{
			path: '/bd/list',
			name: 'Bd',
			component: () => import('@/views/bd/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //BD员工列表
		{
			path: '/bd/maintain/list',
			name: 'Bd',
			component: () => import('@/views/bd/maintain/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //BD维护信息列表
		{
			path: '/bd/maintain/goods/list',
			name: 'Bd',
			component: () => import('@/views/bd/maintain/goods/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //BD维护商品列表
		{
			path: '/bd/maintain/store/list',
			name: 'Bd',
			component: () => import('@/views/bd/maintain/store/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //BD维护店铺列表
		{
			path: '/hotword/list',
			name: 'hotWord',
			component: () => import('@/views/hotword/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //热搜词
		{
			path: '/hotword/addHotword',
			name: 'addhotWord',
			component: () => import('@/views/hotword/addHotword.vue')
		}, //添加热搜词
		{
			path: '/hotword/edit',
			name: 'edithotWord',
			component: () => import('@/views/hotword/edit.vue')
		}, //编辑热搜词
		{
			path: '/carousel/list',
			name: 'carouselList',
			component: () => import('@/views/carousel/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //轮播图列表
		{
			path: '/carousel/add',
			name: 'addCarousel',
			component: () => import('@/views/carousel/add.vue')
		}, //轮播图添加
		{
			path: '/carousel/edit',
			name: 'editCarousel',
			component: () => import('@/views/carousel/edit.vue')
		}, //轮播图修改
		{
			path: '/goods/list',
			name: 'goodsList',
			component: () => import('@/views/goods/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //商品管理
		{
			path: '/goods/add',
			name: 'goodsAdd',
			component: () => import('@/views/goods/add.vue')
		}, //商品添加
		{
			path: '/goodsCategory/list',
			name: 'goodsCategory',
			component: () => import('@/views/goods/goodsCategory/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //商品分类
		{
			path: '/logistics/list',
			name: 'logistics',
			component: () => import('@/views/logistics/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //运单列表
		{
			path: '/logistics/detail',
			name: 'detailLogistics',
			component: () => import('@/views/logistics/detail.vue')
		}, //运单列表详情
		{
			path: '/syslog/list',
			name: 'syslogList',
			component: () => import('@/views/syslog/list.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //操作日志
		{
			path: '/syslog/loginList',
			name: 'syslogLoginList',
			component: () => import('@/views/syslog/loginList.vue'),
			meta: {
				keepAlive: true // 需要缓存false//不需要缓存
			  }
		}, //登录日志
		{
			path: '/syslog/detail',
			name: 'syslogDetail',
			component: () => import('@/views/syslog/detail.vue')
		}, //日志详情
    {
			path: '/group/after/list',
			name: 'groupAfterList',
			component: () => import('@/views/group-buying/group-after/list.vue')
		}, //团购售后列表
    {
			path: '/group/after/getById',
			name: 'groupAfterDetail',
			component: () => import('@/views/group-buying/group-after/detail.vue')
		}, //团购售后详情
    {
			path: '/group/order/list',
			name: 'groupOrderList',
			component: () => import('@/views/group-buying/group-order/list.vue')
		}, //团购订单列表
    {
			path: '/group/order/getById',
			name: 'groupOrderDetail',
			component: () => import('@/views/group-buying/group-order/detail.vue')
		}, //团购订单详情
    {
			path: '/group/broker/list',
			name: 'brokerList',
			component: () => import('@/views/group-buying/group-broker/list.vue')
		},// 团长信息列表
    {
			path: '/group/broker/getById',
			name: 'brokerDetail',
			component: () => import('@/views/group-buying/group-broker/detail.vue')
		},// 团长信息详情
    {
			path: '/group/cashout/list',
			name: 'cashoutList',
			component: () => import('@/views/group-buying/group-cashout/list.vue')
		},//提现列表
    {
			path: '/group/cashout/getById',
			name: 'groupCashoutDetail',
			component: () => import('@/views/group-buying/group-cashout/detail.vue')
		},//提现详情
    {
			path: '/group/goods/init/list',
			name: 'groupGoodsList',
			component: () => import('@/views/group-buying/group-goods/list.vue')
		} ,// 供货商团购商品
    {
			path: '/group/goods/add',
			name: 'groupGoodsList',
			component: () => import('@/views/group-buying/group-goods/add.vue')
		} ,// 供货商团购商品添加与编辑

    {
			path: '/group/goods/list',
			name: 'groupGoodsList',
			component: () => import('@/views/group-buying/group-goods/brokerlist.vue')
		} ,// 团长团购商品
    {
			path: '/group/goods/getById',
			name: 'groupGoodsDetail',
			component: () => import('@/views/group-buying/group-goods/detail.vue')
		} // 团购商品详情
    ,{
      path: '/capital/monthBillList',
      name: 'monthBillList',
      component: () => import('@/views/capital/monthBillList.vue'),
      meta: {
        keepAlive: true // 需要缓存false//不需要缓存
      }
    },{
      path: '/capital/settlementDetail',
      name: 'settlementDetail',
      component: () => import('@/views/capital/settlementDetail.vue'),
      meta: {
        keepAlive: true // 需要缓存false//不需要缓存
      }
	  },{
      path: '/freshOrderAfterService/returnStoreList',
      name: 'returnStoreList',
      component: () => import('@/views/capital/returnStoreList.vue'),
      meta: {
        keepAlive: true // 需要缓存false//不需要缓存
      }
	  }
	  ,{
		path: '/storeWithdrawalLog/page',
		name: 'cashOutList',
		component: () => import('@/views/capital/cashOutList.vue'),
		meta: {
		  keepAlive: true // 需要缓存false//不需要缓存
		}
	  }
	],
},
{
	path: '*',
	name: 'error404',
	component: resolve => require(['@/components/error-page/404'], resolve)
},
]
// 不需要token的白名单
const whiteList = ['/login', '/404']


const router = new VueRouter({
	// mode: 'history',
	mode: 'hash',
	routes
})

// 防止路由点击多次
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

export default router
